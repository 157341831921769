import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../_config/api.config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  pieChartContentType = 'organizer';
  tabs = [1, 2];

  loading = true;
  loadingList = true;
  listData = new Array(3).fill({}).map((_i, index) => {
    return {
      href: 'http://ng.ant.design',
      title: `ant design part ${index}`,
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team.',
      content:
        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.'
    };
  });

  //Bar chart
  barChartData: any[];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Days';
  showYAxisLabel = true;
  yAxisLabel = 'Amount';

  colorScheme = {
    domain: ['rgb(86, 173, 253)']
  };

  //Pie chart
  pieChartData: any[];
  view: any = [undefined, 345];
  view1: any[] = [430, 257];
  showLegend1 = true;

  colorScheme1 = {
    domain: ['rgb(24, 144, 255)', 'rgb(19, 194, 194)', 'rgb(47, 194, 91)', 'rgb(250, 204, 20)', 'rgb(240, 72, 100)', 'rgb(133, 67, 224)']
  };
  showLabels1 = true;
  explodeSlices = false;
  doughnut = false;
  userType: 'A' | 'V' = 'A'
  public dashboardDeails: any = [];
  public topFiveEvents: any = [];
  public topFiveOrganizers: any = [];
  /**for bar chart */
  public transactionData: any = [];
  public transactionfilterTypes: any = [
    { name: 'Day', isActive: true },
    { name: 'Week', isActive: false },
    { name: 'Month', isActive: false },
    { name: 'Year', isActive: false },
  ]

  public routes = {
    user: 'manage-user',
    event: 'manage-event',
    transaction: 'transaction-history',
    enquries: 'contact-us',
    inappropriateReport: 'manage-event-reports',
    eventRequest: 'feature-event-request'
  }

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.getDashboardDetails({ filterType: 'day' });
    const userData = localStorage.getItem('user_details');
    const userInfo = JSON.parse(userData);
    this.userType = userInfo.type

  }

  public getDashboardDetails(param?) {
    this.http.get(URLS.dashboard, { params: param })
      .subscribe(responce => {
        if (responce['code'] === 200) {
          this.transactionData = [];
          this.topFiveEvents = [];
          this.topFiveOrganizers = [];
          this.dashboardDeails = responce['data'];
          console.log(this.dashboardDeails);

          this.xAxisLabel = param.filterType.charAt(0).toUpperCase() + param.filterType.slice(1) + 's';
          this.formmatchartData(responce['data']['top5EventOrganizers'], this.topFiveOrganizers, 'organizer_name', 'total_events');
          this.formmatchartData(responce['data']['top5EventCategories'], this.topFiveEvents, 'category_name', 'total_events_published');
          this.formmatchartData(responce['data']['transactionBarChart'], this.transactionData, param.filterType, 'amount');
          this.pieChartData = this.userType == 'A' ? this.topFiveOrganizers : this.topFiveEvents;
          this.barChartData = this.transactionData;
        }
      })
  }

  /**format responce in chart required fromat
   * @param responceArr responcearray to be format 
   */
  public formmatchartData(responceArr, arrayToFilled, nameParam, valueParam) {
    for (let i = 0; i < responceArr.length; i++) {
      arrayToFilled.push({
        name: responceArr[i][nameParam],
        value: responceArr[i][valueParam]
      })
    }
  }

  public onPieChartChange(type) {
    this.pieChartData = (type === 'organizer') ? this.topFiveOrganizers : this.topFiveEvents
  }

  public ontransactionfilterClick(type) {
    this.transactionfilterTypes.forEach(element => {
      if (element.isActive === true) element.isActive = false;
    });
    type.isActive = true;
    this.getDashboardDetails({ filterType: type.name.toLowerCase() });
  }
}

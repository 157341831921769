import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { URLS } from '../api.config';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-vendor-edit-profile',
  templateUrl: './vendor-edit-profile.component.html',
  styleUrls: ['./vendor-edit-profile.component.scss']
})
export class VendorEditProfileComponent implements OnInit {
  validateForm!: FormGroup;
  isLoadingOne = false;
  userDetails: any = {};
  user: any;
  imagePreview: any;

  constructor(private fb: FormBuilder, private http: HttpClient, private notification: NzNotificationService,
  ) { }

  ngOnInit(): void {
    const userData = localStorage.getItem('user_details');
    const userInfo = JSON.parse(userData);
    this.user = userInfo
    this.initializeForm();
    this.fetchUserData();
  }

  // Initialize the form
  initializeForm(): void {
    this.validateForm = this.fb.group({
      profile_pic: '',
      first_name: [null, [Validators.required, Validators.minLength(3)]],
      last_name: [null, [Validators.required, Validators.minLength(3)]],
      user_name: [null, [Validators.required, Validators.minLength(3)]],
      photo: [null],
      mobile: [null],
      bank_name: [null, [Validators.required]],
      account_number: [
        null,
        [Validators.required],
      ],
      iban: [
        null,
        [Validators.required],
      ],
      swift_code: [
        null,
        [Validators.required],
      ],
    });
  }

  // Fetch user data from the API
  fetchUserData(): void {
    const apiUrl = URLS.userDetails; // Replace with your API endpoint
    this.http.get(apiUrl, { params: { customer_id: this.user.customer_id } }).subscribe(
      (response: any) => {
        this.userDetails = response.data[0];
        this.user.profile_pic = response.data[0].profile_pic
        localStorage.setItem('user_details', JSON.stringify(this.user));
        this.prefillForm();
      },
      (error) => {
        console.error('Error fetching user data', error);
      }
    );
  }

  // Prefill the form with fetched data
  prefillForm(): void {
    this.validateForm.patchValue({
      first_name: this.userDetails.first_name,
      last_name: this.userDetails.last_name,
      user_name: this.userDetails.user_name,
      bank_name: this.userDetails.bank_info.bankName,
      account_number: this.userDetails.bank_info.accountNumber,
      iban: this.userDetails.bank_info.iban,
      swift_code: this.userDetails.bank_info.bankSwiftCode,
      profile_pic: this.user.profile_pic
    });

    this.imagePreview = this.user.profile_pic
  }

  // Submit the form
  submitForm(): void {
    if (this.validateForm.valid) {
      this.isLoadingOne = true;
      const apiUrl = URLS.verifyProfile;
      const formData = {
        customer_id: this.user.customer_id,
        ...this.validateForm.value,
        profile_pic: this.imagePreview
      };

      this.http
        .put(apiUrl, formData)
        .pipe(finalize(() => (this.isLoadingOne = false)))
        .subscribe(
          (response: any) => {
            this.notification.success(
              'Profile Updated',
              'Your details has been saved successfully!'
            );
            this.fetchUserData();
            console.log('Form submitted successfully', response);
          },
          (error) => {
            console.error('Error submitting form', error);
          }
        );
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      });
    }
  }

  onImageSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files
    console.log(files);

    let file: any = files.length ? files[0] : '';
    if (file) {
      this.validateForm.patchValue({ profile_pic: file });
      this.validateForm.get('profile_pic').updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      (fileInput as HTMLInputElement).click();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { URLS } from '../_config/api.config';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-wallet-index',
  templateUrl: './wallet-index.component.html',
  styleUrls: ['./wallet-index.component.scss']
})
export class WalletIndexComponent implements OnInit {

  public userlisting = "active";
  amt: any;
  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  column_settings: any = [
    { primaryKey: 'event_title', header: 'Event Name', sort: true },
    { primaryKey: 'event_url', header: 'Event Cover Image', image: true },
    { primaryKey: 'address', header: 'Event Venue	', sort: true },
    { primaryKey: 'event_start_date	', header: 'Date & Time', sort: true },
    { primaryKey: 'sales_total_no_of_tickets', header: 'Booking' },
    { primaryKey: 'balance', header: 'Total Sales ' },
    { primaryKey: 'status', header: 'Payment Withdrawal Status ' },
  ];
  apiUrl = {
    list: URLS.walletList,
  };
  actions = {
  };
  widthConfig = ['150px', '150px', '150px', '150px', '150px', '150px', '180px'];
  scrollConfig = { x: '800px', y: '100%' };
  queryParams = { status: 'status', validuser: "active", page_id: 'customer_id', type: 'L' };
  ngOnInit() {
    this.fetchUserData()
  }

  refreshList: boolean = false
  doRefreshList(type: string) {
    this.refreshList = true
    this.queryParams['type'] = type
    if (type == 'P') {
      this.column_settings = [
        { primaryKey: 'event_title', header: 'Event Name', sort: true },
        { primaryKey: 'event_url', header: 'Event Cover Image', image: true },
        { primaryKey: 'address', header: 'Event Venue	', sort: true },
        { primaryKey: 'event_start_date	', header: 'Date & Time', sort: true },
        { primaryKey: 'sales_total_no_of_tickets', header: 'Booking' },
        { primaryKey: 'balance', header: 'Total Sales ' },
        {
          primaryKey: 'event-route', header: 'Payment Withdrawal Status '
        }
      ];
    } else {
      this.column_settings = [
        { primaryKey: 'event_title', header: 'Event Name', sort: true },
        { primaryKey: 'event_url', header: 'Event Cover Image', image: true },
        { primaryKey: 'address', header: 'Event Venue	', sort: true },
        { primaryKey: 'event_start_date	', header: 'Date & Time', sort: true },
        { primaryKey: 'sales_total_no_of_tickets', header: 'Booking' },
        { primaryKey: 'balance', header: 'Total Sales ' },
        { primaryKey: 'status', header: 'Payment Withdrawal Status ' },
      ];
    }
    setTimeout(() => {
      this.refreshList = false
    }, 200);
  }

  fetchUserData(): void {
    const apiUrl = URLS.walletAmount; // Replace with your API endpoint
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        this.amt = response.data.amount;
      },
      (error) => {
        console.error('Error fetching user data', error);
      }
    );
  }
}

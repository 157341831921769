import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { CookieService } from 'ngx-cookie-service';

// export interface User {
//   id: number;
//   username: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   token: string;
// }
const userList = ['U', 'Lucy', 'Tom', 'Edwardfdf'];

@Component({
  selector: 'app-user-settings-popup',
  templateUrl: './user-settings-popup.component.html'
})
export class UserSettingsPopupComponent {
  // isLoggedIn: boolean = true;
  // public currentUser: string;
  text: string = userList[3];
  userType: 'A' | 'V' = 'A';
  userImg: any = '';



  clickMe(routerLink): void {
    this.router.navigate([routerLink]);
  }
  constructor(private router: Router,
    private authService: AuthenticationService, private cookieService: CookieService) {
    // this.authService.currentUser.subscribe(x => this.currentUser = x);
    const userData = localStorage.getItem('user_details');
    const userInfo = JSON.parse(userData);
    this.userImg = userInfo.profile_pic
    this.userType = userInfo.type
  }

  logout() {
    this.authService.logout().subscribe((data) => {
      if (data['data'] && data['code'] === 200) {
        // this.cookieService.delete('token');
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      }
    },
      (err) => {
        console.error(err);
      }
    );
  }
}
